// Import React (necessary for JSX) and the CSS file
import React from 'react';
import './GameTable.css';

// Functional component definition
function ContactPage() {
  return (
    <div className="contact-page">
      <a href="mailto:contact@gamematch.info">contact@gamematch.info</a>
    </div>
  );
}

// Export the component so it can be used in other parts of your application
export default ContactPage;