const fieldMap = {
    'name':{
        caption: 'Title',
        isVisible: true,
        showFilter: true,
        showTable: true,
        showDetails: true
    },
    'youtube_gameplay_search_url': {
        caption: 'Youtube',
        isVisible: true,
        showFilter: false,
        showTable: true,
        showDetails: true,
        customRender: {
            type: 'link',
            txt: 'link'
        }
    },
    'total_popularity':{
        caption: 'Popularity',
        isVisible: true,
        showFilter: true,
        showTable: true,
        showDetails: true
    },
    'adjusted_total_score':{
        caption: 'Combined score',
        isVisible: true,
        showFilter: true,
        showTable: true,
        showDetails: true
    },
    'ag_releaseDate':{
        caption: 'Release date',
        isVisible: true,
        showFilter: true,
        showTable: true,
        showDetails: true
    },
    'ag_NO_OF_NETWORK_PLAYERS':{
        caption: 'Network players',
        isVisible: true,
        showFilter: true,
        showTable: true,
        showDetails: true
    },
    'ag_NO_OF_PLAYERS':{
        caption: 'Couch players',
        isVisible: true,
        showFilter: true,
        showTable: true,
        showDetails: true
    },
    'ag_psvr2':{
        caption: 'PSVR2',
        isVisible: true,
        showFilter: true,
        showTable: true,
        showDetails: true
    },
    'defaultProduct_descriptions_LONG':{
        caption: 'df Description',
        isVisible: true,
        showFilter: false,
        showTable: false,
        showDetails: true
    },
    'defaultProduct_descriptions_SHORT':{
        caption: 'df Short description',
        isVisible: true,
        showFilter: false,
        showTable: false,
        showDetails: true
    },
    'description_long':{
        caption: 'Description',
        isVisible: true,
        showFilter: false,
        showTable: false,
        showDetails: true
    },
    'description_short':{
        caption: 'Short description',
        isVisible: true,
        showFilter: false,
        showTable: false,
        showDetails: true
    },
    'combinedLocalizedGenres':{
        caption: 'Genres',
        isVisible: true,
        showFilter: true,
        showTable: true,
        showDetails: true,
        disableSort: true
    },
    'x_category':{
        caption: 'More category',
        isVisible: true,
        showFilter: true,
        showTable: true,
        showDetails: true,
        disableSort: true
    },
    'x_tags_ar_top5':{
        'caption': 'Top tags',
        'isVisible': true,
        'showFilter': false,
        'showTable': true,
        'showDetails': false,
        'disableSort': true
    },
    'x_tags_ar':{
        'caption': 'Tags',
        'isVisible': false,
        'showFilter': true,
        'showTable': false,
        'showDetails': true,
        'disableSort': true
    },

}

module.exports = { fieldMap };

// based on fieldMap, create a list of columns for the table like this one if field is showTable: true

// const columnDefinitions = [
//     {
//         Header: 'img',
//         accessor: 'img',
//     },
//     {
//       Header: 'Name',
//       accessor: 'name',
//     },


