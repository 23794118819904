// function to request filter settings from graphql server based on a list of fields

// query MyQuery {
//     game_filters {
//       reviews_totalRatingsCount {
//         filter_type
//         max
//         min
//         vs_list
//       }
//       psn_concept_id {
//         filter_type
//         max
//         min
//         vs_list
//       }
//     }
//   }

// sample list of fields
// const fields = [
//     'reviews_totalRatingsCount',
//     'psn_concept_id'
// ]
// construct query function, gets a list of fields  and returns a query string
export const constructFilterQuery = (fields) => {
  const query = `query filterSettings {
      game_filters {
        ${fields.map(field => `
          ${field} {
            filter_type
            max
            min
            vs_list
          }
        `)}
      }
    }
  `;
  return query;
}
