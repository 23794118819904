import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useSearchParams } from 'react-router-dom';
import './FilterPanel.css';
import { constructFilterQuery } from './filterListQuery';
import { useCallback } from 'react';
import axios from 'axios';
const { fieldMap } = require('./field_map.js');

const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'var(--primary-bg-color)',
      color: 'var(--text-color)',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'var(--accent-color)' : 'var(--secondary-bg-color)',
      color: 'var(--text-color)',
    }),
    // Add other parts you want to customize
};
  
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

const filterFieldsIds = Object.keys(fieldMap).map((field) => {
    if (fieldMap[field].showFilter) {
        return field;
    }
    return null;
}).filter(column => column !== null);

const FilterPanel = ({onFilterApply}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tryCatchParse = useCallback((param, defaultValue) => {
        var parsed
        try {
            parsed = searchParams.get(param) ? JSON.parse(searchParams.get(param)) : defaultValue;
        } catch (error) {
            console.error('Error parsing', param, ':', error);
            parsed = defaultValue;
        }
        return parsed;
    }, [searchParams]);
    
    const [filterFields2, setFilterFields2] = useState([]);    
    // const initialFilters = {} //searchParams.get('filters') ? JSON.parse(searchParams.get('filters')) : {};
    // set intial filters to searchParams.get('filters') but only if it parses correctly
    const initialFilters = tryCatchParse('filters', {});
    const [filters, setFilters] = useState(initialFilters);
    const isPanelOpenFromParams = searchParams.get('isPanelOpen') === 'true';
    const [isPanelOpen, setIsPanelOpen] = useState(isPanelOpenFromParams);
//   const [dateRange, setDateRange] = useState([null, null]); // [start, end]
    const initialcCllapsed = tryCatchParse('collapsed', {});
    const [collapsed, setCollapsed] = useState(initialcCllapsed);

    const fetchFilters = useCallback(async () => {
        try {
            if (filterFields2.length) {
                return;
            }
            const query = constructFilterQuery(filterFieldsIds);
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(apiUrl, {
                query: query,
            });
            let filterRes= response.data.data.game_filters[0];
            // update each filter in filterRes with a label from fieldMap
            Object.keys(filterRes).forEach(id => {
                // Directly access the matching entry in fieldMap and update the label
                if (fieldMap[id]) { // Check if the fieldMap contains the id
                    filterRes[id].label = fieldMap[id].caption;
                    filterRes[id].id = id;
                }
            });
            setFilterFields2(filterRes);

        } catch (error) {
            console.error('Error fetching filters:', error);
        }
    }, [filterFields2.length]); // Add all external dependencies here

    useEffect(() => {
        fetchFilters();
    }, [fetchFilters]); // fetchFilters is stable thanks to useCallback, so this effect runs only once


    const setFiltersSerachParams = (filters) => {
        const params = new URLSearchParams();
        if (Object.keys(filters).length) {
            params.set('filters', JSON.stringify(filters));
        }
        setSearchParams(params, { replace: true });
    };

    // Parse filters from URL params
    useEffect(() => {
        // const currentFilters = tryCatchParse('filters', {});
        const currentCollapsed = tryCatchParse('collapsed', {});
        const currentIsPanelOpen = tryCatchParse('isPanelOpen', 'true') === 'true';


        if (currentCollapsed !== collapsed || currentIsPanelOpen !== isPanelOpen) {
            const params = new URLSearchParams(window.location.search);
                if (Object.keys(collapsed).length) {
                    params.set('collapsed', JSON.stringify(collapsed));
                }
                params.set('isPanelOpen', isPanelOpen);
                setSearchParams(params, { replace: true });
        }
    }, [filters, collapsed, isPanelOpen, setSearchParams, searchParams, tryCatchParse]);

    const clearFilter = (fieldId, bound) => {
        setFilters((prevFilters) => ({
        ...prevFilters,
        [fieldId]: {
            ...prevFilters[fieldId],
            [bound]: '',
        },
        }));
    };
    
    const toggleCollapse = (fieldId) => {
        setCollapsed((prevCollapsed) => ({
        ...prevCollapsed,
        [fieldId]: !prevCollapsed[fieldId],
        }));
    };

    const handleInputChange = (fieldId, value) => {
        setFilters((prevFilters) => ({
        ...prevFilters,
        [fieldId]: value,
        }));
    };

    const applyFilters = () => {
        console.log('Applying filters:', filters);
        setFiltersSerachParams(filters);
        onFilterApply(filters);
        // Integrate with GraphQL query here
    };

    const resetFilters = () => {
        setFilters({});
        setSearchParams({});
    };

    const renderFilterInput = (field) => {
        // check if label is undefined, replace it with id
        switch (field.filter_type) {
        case "NUMBER":
            return !collapsed[field.id] && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                type="number"
                placeholder={field.min + " Min"}
                onChange={(e) => handleInputChange(field.id, { ...filters[field.id], min: e.target.value })}
                value={filters[field.id]?.min || ''}
                className="filter-input"
                />
                {filters[field.id]?.min && (
                <button onClick={() => clearFilter(field.id, 'min')} className="clear-button">
                    x
                </button>
                )}
                <input
                type="number"
                placeholder={field.max + " Max"}
                onChange={(e) => handleInputChange(field.id, { ...filters[field.id], max: e.target.value })}
                value={filters[field.id]?.max || ''}
                className="filter-input"
                />
                {filters[field.id]?.max && (
                <button onClick={() => clearFilter(field.id, 'max')} className="clear-button">
                    x
                </button>
                )}
            </div>
            );
        case "LIST":
            return !collapsed[field.id] && (
            <Select
                styles={customStyles} 
                options={field.vs_list.sort().map((opt) => ({ value: opt, label: opt }))}
                isMulti
                onChange={(selectedOptions) =>
                handleInputChange(
                    field.id,
                    selectedOptions.map((option) => option.value)
                )
                }
                value={filters[field.id]?.map((val) => ({ value: val, label: val })) || []}
            />
            );
        case "TEXT":
            return !collapsed[field.id] && (
            <input
                type="text"
                onChange={(e) => handleInputChange(field.id, e.target.value)}
                value={filters[field.id] || ''}
            />
            );
        case "DATE":
            return !collapsed[field.id] && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                type="date"
                onChange={(e) => handleInputChange(field.id, { ...filters[field.id], min: e.target.value })}
                // don't let to set up les than field.min or greater than field.max
                // onChange={(e) => handleInputChange(field.id, { ...filters[field.id], min: (e.target.value < field.min ? field.min : e.target.value > field.max ? field.max : e.target.value)})}
                value={filters[field.id]?.min || field.min}
                className="filter-input"
                />
                {filters[field.id]?.min && (
                <button onClick={() => clearFilter(field.id, 'min')} className="clear-button">
                    x
                </button>
                )}
                <input
                type="date"
                onChange={(e) => handleInputChange(field.id, { ...filters[field.id], max: e.target.value })}
                value={filters[field.id]?.max || field.max}
                className="filter-input"
                />
                {filters[field.id]?.max && (
                <button onClick={() => clearFilter(field.id, 'max')} className="clear-button">
                    x
                </button>
                )}
            </div>
            );
        case "BOOL":
            return !collapsed[field.id] && (
                <select
                onChange={(e) => handleInputChange(field.id, e.target.value)}
                value={filters[field.id] || ''}
            >
                <option value="">--</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
            </select>
            );
        default:
            return null;
        }
    };

    return (
        <aside className="filter-panel">
        <button onClick={() => setIsPanelOpen(!isPanelOpen)}>Filters</button>
        {isPanelOpen && (
            <div className="filter-panel__content">
                {
                    Object.entries(filterFields2).map(([id, field]) => {
                    return (
                        <div className="filter-field" key={id}>
                        <button onClick={() => toggleCollapse(id)} className="collapse-button">
                            {field.label}
                        </button>
                        {renderFilterInput(field)}
                        </div>
                    );
                    })
                }
                <div className="filter-actions">
                    <button onClick={applyFilters}>Apply</button>
                    <button onClick={resetFilters}>Reset</button>
                </div>
            </div>
        )}
        </aside>
    );
};

export default FilterPanel;
