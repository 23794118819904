import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './GameTable.css'; // Ensure this contains responsive and other styles
import FilterPanel from './FilterPanel';
import PaginationComponent from './PaginationComponent';
import LoadingSpinner from './LoadingSpinner';
import NoDataMessage from './NoDataMessage';
import ErrorMessage from './ErrorMessage';
import ColumnCustomizationMenu from './ColumnCustomizationMenu';
import { fetchAllGamesQuery } from './gameQueries';
const { fieldMap } = require('./field_map.js');


const columnDefinitions = Object.keys(fieldMap).map((field) => {
    if (fieldMap[field].showTable) {
        // Check if the current field should have sorting disabled
        const disableSortBy = fieldMap[field].disableSort || false;
        return {
            Header: fieldMap[field].caption,
            accessor: field,
            // Use the determined value to disable sorting
            disableSortBy: disableSortBy,
        };
    }
    return null;
}).filter((column) => column !== null);


const GameTable = () => {
    let fetchGames;
    const [totalCount, setTotalCount] = useState(0); // Total number of games matching the current filters
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [lastQuery, setLastQuery] = useState('');
    // const [sortByGlobal, setSortBy] = useState([{ id: 'name', desc: false }]);
    // const [pageParams, setPageParams] = useState({ page: 1, pageSize: 30 });

    const defaultParams = {
        sort: [{ id: 'adjusted_total_score', desc: true }],
        page: 1,
        pageSize: 30,
        filters: {}
    };
    
    const [sortByGlobal, setSortBy] = useState(() => {
        // Parse the sort parameter from URL or use default
        try {
            const sortParam = searchParams.get('sort');
            return sortParam ? JSON.parse(decodeURIComponent(sortParam)) : defaultParams.sort;
        } catch (e) {
            console.error('Error parsing sort parameter:', e);
            return defaultParams.sort;
        }
    });

    const [queryFilters, setQueryFilters] = useState(() => {
        try {
            const filtersParam = searchParams.get('filters');
            return filtersParam ? JSON.parse(decodeURIComponent(filtersParam)) : defaultParams.filters;
        } catch (e) {
            console.error('Error parsing filters parameter:', e);
            return defaultParams.filters;
        }
    });

    const [pageParams, setPageParams] = useState(() => {
        try {
        // Parse the page parameters from URL or use default
            const page = searchParams.get('page');
            const pageSize = searchParams.get('pageSize');
            return {
                page: page ? parseInt(page, 10) : defaultParams.page,
                pageSize: pageSize ? parseInt(pageSize, 10) : defaultParams.pageSize,
            };
        } catch (e) {
            console.error('Error parsing page parameters:', e);
            return  {page: defaultParams.page, pageSize:  defaultParams.pageSize};
        }
    });

    //  set url params to default

    useEffect(() => {
        // Retrieve the current search parameters from the URL
        const currentSearchParams = new URLSearchParams(window.location.search);
        // Update only the parameters you're interested in, leaving the rest unchanged
        currentSearchParams.set('sort', encodeURIComponent(JSON.stringify(sortByGlobal)));
        currentSearchParams.set('page', pageParams.page.toString());
        currentSearchParams.set('pageSize', pageParams.pageSize.toString());
    
        // Update the URL with the new search parameters, keeping other parameters intact
        setSearchParams(currentSearchParams, { replace: true });
    }, [sortByGlobal, pageParams, setSearchParams]);
    
    const [columnsVisibility, setColumnsVisibility] = useState(
        columnDefinitions.reduce((acc, column) => {
        acc[column.accessor] = true;
        return acc;
        }, {})
    );


    const navigate = useNavigate();

    const columns_all = React.useMemo(() => 
        columnDefinitions.map(column => ({
        ...column,
        isVisible: columnsVisibility[column.accessor],
        })),
        [columnsVisibility]
    );

    const columns = React.useMemo(() => columns_all.filter(column => column.isVisible), [columns_all]); // Filtering based on visibility

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }, // Capture sorting state
    } = useTable({
        // filter columns by visibility
        columns,
        data,
        initialState: {
            // Optionally initialize with default sorting
            sortBy: sortByGlobal
        },
    }, useFilters, useSortBy, usePagination);

    const setDefaultUrlParams = useCallback(() => {
        const currentSearchParams = new URLSearchParams(window.location.search);
        currentSearchParams.set('sort', encodeURIComponent(JSON.stringify(defaultParams.sort)));
        currentSearchParams.set('page', defaultParams.page.toString());
        currentSearchParams.set('pageSize', defaultParams.pageSize.toString());
        currentSearchParams.set('filters', "{}")
        // currentSearchParams.set('page', defaultParams.page.toString());
        setSearchParams(currentSearchParams, { replace: true });
        console.log(currentSearchParams.toString());
    }, [defaultParams, setSearchParams ]);

    const resetUrlAndReload = useCallback(() => {
        if (sortByGlobal !== defaultParams.sort || pageParams.page !== defaultParams.page || pageParams.pageSize !== defaultParams.pageSize || queryFilters !== defaultParams.filters) {
            setDefaultUrlParams();

            fetchGames();

        } else {
            console.log('already default');
        }
    }, [defaultParams, pageParams, fetchGames,  queryFilters, setDefaultUrlParams, sortByGlobal]);
    // Function to reset URL parameters and reload data on error, but only if URL parameters were not default
    // const resetUrlAndReload = () => {
    //     if (sortByGlobal !== defaultParams.sort || pageParams.page !== defaultParams.page || pageParams.pageSize !== defaultParams.pageSize || queryFilters !== defaultParams.filters) {
    //         setDefaultUrlParams();

    //         fetchGames();

    //     } else {
    //         console.log('already default');
    //     }
    // }

    fetchGames = useCallback(async () => {
        try {
            const sortField = sortBy.length ? sortBy[0].id : 'name';
            const sortOrder = sortBy.length && sortBy[0].desc ? 'desc' : 'asc';
            setSortBy(sortBy);

            const params = {
                sort: { field: sortField, direction: sortOrder },
                filter: queryFilters,
                page: pageParams.page,
                pageSize: pageParams.pageSize,
            };
            const query = fetchAllGamesQuery(params);
            if (query === lastQuery) {
                return;
            }
            setLastQuery(query);
    
            setLoading(true);
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(apiUrl, {
                query: query,
            });
            setTotalCount(response.data.data.all_games.results_count || 0)
            setData(response.data.data.all_games.games || []);
        } catch (error) {
            console.error('Error fetching games:', error);
            resetUrlAndReload();
            // setError('Failed to fetch games.');
        } finally {
            setLoading(false);
        }
    }, [queryFilters, sortBy, pageParams, lastQuery, resetUrlAndReload]); // Add all external dependencies here
    
    useEffect(() => {
        fetchGames();
    }, [fetchGames]);

    


    // useEffect(() => {
    //     fetchGames();
    // }, [searchParams, sortBy, pageParams]); // Refetch when search params change




    const handlePageChange = (page) => {
        setPageParams(prev => ({
            ...prev,
            page
        }));
    };
    const handlePageSizeChange = (pageSize) => {
        setPageParams(prev => ({
            ...prev,
            pageSize
        }));
    };
    const handleColumnVisibilityChange = (columnAccessor, isVisible) => {
        setColumnsVisibility(prev => ({
            ...prev,
            [columnAccessor]: isVisible
        }));
    };

    const handleApplyFilters = (filters) => {
        setQueryFilters(filters);
        setPageParams(prev => ({...prev, page: 1}));
        fetchGames();
    };

    
    if (loading) return <LoadingSpinner />;
    if (error) return <ErrorMessage message={error} />;
    // if (!data.length) return <NoDataMessage />;

    return (


    <div className="main-container">
        <Helmet>
            <title>Game Match</title>
            <meta name="descrimption" content="Game match PS4/PS5 table" />
        </Helmet>
        <div className="filter-panel">
            <FilterPanel 
                onFilterApply={handleApplyFilters}
            /> 
        </div>
        <div className="table-and-pagination">
            {/* <ColumnCustomizationMenu 
                columns={columns_all.map(column => ({
                ...column,
                isVisible: columnsVisibility[column.accessor] !== false // Ensure correct checked state
                }))}
                onColumnVisibilityChange={handleColumnVisibilityChange} 
            /> */}
            <table {...getTableProps()} className="table">
            <thead>
                {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        {/* Sort direction indicator */}
                        <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                        </span>
                    </th>
                    ))}
                </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    const masterMedia = row.original.ag_media.find(media => media.role === 'MASTER');
                    const firstCellContent = row.cells[0].render('Cell'); // Get the first field's text

                    return (
                        <tr {...row.getRowProps()}>
                            {/* Combine the image and the first field's text into the first <td> */}
                            {/* display: flex;
                                align-items: center; */}
                            <td>
                                <div className="first_column_div">
                                    {masterMedia && <img src={masterMedia.url} alt="Thumbnail" style={{maxWidth: '60px', maxHeight: '60px', marginRight: '10px'}} />}
                                    <div> 
                                        <a href={`https://store.playstation.com/en-us/concept/${row.original.psn_concept_id}`}>{firstCellContent}</a>
                                    </div>
                                </div>
                            </td>
                            {row.cells.slice(1).map(cell => { // Start from the second cell since the first one is already rendered
                                const cellValue = cell.value;
                                let displayValue = cellValue;

                                // If the cell value is an array, join the first 3 items with commas
                                if (Array.isArray(cellValue)) {
                                    displayValue = cellValue.slice(0, 5).join(', ');
                                } else if (typeof cellValue === 'boolean') { // Check if the cell value is a boolean
                                    displayValue = cellValue.toString(); // Convert boolean to string for rendering
                                }
                                if (fieldMap[cell.column.id].customRender) {
                                    if (fieldMap[cell.column.id].customRender.type === 'link') {
                                        displayValue = <a href={cellValue}>{fieldMap[cell.column.id].customRender.txt}</a>;
                                    }
                                }

                                return (
                                    <td {...cell.getCellProps()}>
                                        {displayValue}
                                        {/* <a href={`/game/${row.original.psn_concept_id}`}>{displayValue}</a> */}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
            </table>
            <div className="footer">
                <PaginationComponent
                    // pageCount={totalCount/pageParams.pageSize} 
                    pageCount={Math.ceil(totalCount / pageParams.pageSize)}
                    currentPage={pageParams.page}
                    canPreviousPage={pageParams.page > 1}
                    canNextPage={pageParams.page < Math.ceil(totalCount / pageParams.pageSize)} 
                    pageSize={pageParams.pageSize}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                />
                <div className='footer-links'><a href={`/contact`}>Contacts</a></div>
            </div>
        </div>

    </div>

    );
};

export default GameTable;
