import React from 'react';

const PaginationComponent = ({
  currentPage,
  pageCount,
  canPreviousPage,
  canNextPage,
  onPageChange,
  onPreviousPage,
  onNextPage,
  onPageSizeChange,
  pageSize,
  pageSizeOptions = [10, 20, 30, 50, 100],
}) => {

  // Correctly use `pageSize` to set the select dropdown's value
  const currentPageSize = pageSizeOptions.includes(pageSize) ? pageSize : pageSizeOptions[0];

  return (
    <div className="pagination">
      <button onClick={() => onPageChange(1)} disabled={!canPreviousPage}>
        {'<<'}
      </button>
      <button onClick={() => onPageChange(currentPage - 1)} disabled={!canPreviousPage}>
        {'<'}
      </button>
      <span>
        Page <strong>{currentPage}</strong> of <strong>{pageCount}</strong>
      </span>
      <button onClick={() => onPageChange(currentPage + 1)} disabled={!canNextPage}>
        {'>'}
      </button>
      <button onClick={() => onPageChange(pageCount)} disabled={!canNextPage}>
        {'>>'}
      </button>
      <select
        value={currentPageSize}
        onChange={(e) => onPageSizeChange(Number(e.target.value))}
      >
        {pageSizeOptions.map(option => (
          <option key={option} value={option}>
            Show {option}
          </option>
        ))}
      </select>
    </div>
  );
};


export default PaginationComponent;
