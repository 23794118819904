// Constructs a query for fetching all games with filters, sorting, and pagination
const { fieldMap } = require('./field_map.js');

export const fetchAllGamesQuery = (params) => {
    // Destructure params to extract filter, sort, and pagination options
    const { sort, filter, page, pageSize } = params;
  
    // Convert filter object to GraphQL filter string if necessary
    // This is highly dependent on your GraphQL schema and how it expects filters to be formatted
    // do proper conversion of json to graphql string:
    const filterString = JSON.stringify(filter).replace(/"/g, '\\"');
    const gamesFields = Object.keys(fieldMap)
      .filter(id => fieldMap[id].showTable === true) // Filter based on .showTable
      .map(id => `${id}`) // Map to the same key/id
      .join('\n              '); // Join with new lines and spaces for formatting
  
    // Construct the GraphQL query string
    // filter_input: ${filterString},
    // with all fields from fieldMap
    const query =  `query {
        all_games(
          limit: ${pageSize},
          offset: ${(page - 1) * pageSize},
          filter_input: {filter_json: "${filterString}"},
          sort_by: "${sort.field}",
          sort_direction: "${sort.direction}"
        ) 
        {
          results_count
          games {
              psn_concept_id
              ag_media {
                  role
                  url
              }
              ${gamesFields}
          }
        }
      }
    `;
    return query;
  };
  

  // psn_concept_id
  // defaultProduct_compatibilityNotices_NO_OF_PLAYERS
  // defaultProduct_compatibilityNotices_NO_OF_NETWORK_PLAYERS
  // compatibilityNotices_NO_OF_PLAYERS
  // compatibilityNotices_NO_OF_NETWORK_PLAYERS
  // metacritic_positiveCount
  // metacritic_neutralCount
  // metacritic_negativeCount
  // defaultProduct_name
  // media {
  //   role
  //   url