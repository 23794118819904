import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import GameTable from './components/GameTable';
import ContactPage from './components/ContactPage';



function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<GameTable />} />
        <Route exact path="/contact" element={<ContactPage />} />
      </Routes>
    </Router>
  );
}


export default App;
